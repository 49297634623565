import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  role: [],
  status: "idle",
  error: null,
};

export const fetchRole = createAsyncThunk(
  "roles/fetchRole",

  async () => {
    let data;
    try {
      const response = await axios.get(`/roles`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }
);

export const createRole = createAsyncThunk("roles/createRole", async (body) => {
  let data;

  try {
    const response = await axios.post(`/roles`, body);
    data = await response.data;

    if ((response.status = 200)) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRole.pending]: (state) => {
      state.status = "loading";
    },

    [fetchRole.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.role = action.payload;
    },

    [fetchRole.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
